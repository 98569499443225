import { jsx as ___EmotionJSX } from "@emotion/core";
import React from 'react';
import { Layout, HeroComponent, AboutMe, WorkExperience, Skills, FavoriteTools, ContactMe } from '../@features';
export default function IndexPage() {
  return ___EmotionJSX(Layout, null, ___EmotionJSX(HeroComponent, {
    appBarVariant: "inverted"
  }), ___EmotionJSX(AboutMe, {
    appBarVariant: "normal"
  }), ___EmotionJSX(WorkExperience, {
    appBarVariant: "normal"
  }), ___EmotionJSX(Skills, {
    appBarVariant: "normal"
  }), ___EmotionJSX(FavoriteTools, {
    appBarVariant: "inverted"
  }), ___EmotionJSX(ContactMe, {
    appBarVariant: "normal"
  }));
}